<script>
	import { SESSION_EXPIRE_SHOW_MODAL_MS, SESSION_MODAL_WARNING_MS } from '../../constants.js';
	import { keepAlive, logout } from '../../utils/api-calls';
	import { data, countdown } from '../../stores.js';
	import { push as navigateTo } from 'svelte-spa-router';
	import { createEventDispatcher } from 'svelte';
	import { _ } from 'svelte-i18n';
	import SpinnerIcon from '../icon/SpinnerIcon.svelte';
	import FullPageModal from './FullPageModal.svelte';

	const dispatch = createEventDispatcher();

	$: value = SESSION_EXPIRE_SHOW_MODAL_MS / 1000 - $countdown;
	let max = SESSION_EXPIRE_SHOW_MODAL_MS / 1000;
	let ajaxRequest = false;

	$: value,
		(function () {
			// TODO: THIS IS A HACK -> IF TIMER EXPIRED, SHOW MODAL SPINNER
			if (value === (SESSION_MODAL_WARNING_MS / 1000) * -1) {
				ajaxRequest = true;
			}
		})();

	$: progressPath = () => {
		if (value <= 0) {
			return '';
		} else if (value >= max) {
			return 'M50,5A45 45 0 1 1 49.9999 5';
		} else {
			const angle = Math.PI * 2 * (value / max);
			const x = 50 + Math.cos(angle - Math.PI / 2) * 45;
			const y = 50 + Math.sin(angle - Math.PI / 2) * 45;
			let path = 'M50,5';
			if (angle > Math.PI) {
				path += 'A45 45 0 0 1 50 95';
			}
			path += `A45 45 0 0 1 ${x} ${y}`;
			return path;
		}
	};

	async function logoutUser() {
		try {
			ajaxRequest = true;
			await logout({ clearSession: false });
			$data = {};
		} catch (err) {
			console.error(err);
			ajaxRequest = false;
		} finally {
			dispatch('close');
			navigateTo('/login');
		}
	}

	let renewAjax = false;
	async function renew() {
		if (ajaxRequest) return;
		try {
			renewAjax = true;
			await keepAlive();
		} catch (err) {
			console.error('Failed to renew session: ', err);
			renewAjax = false;
		} finally {
			dispatch('close');
		}
	}

	const handleKeydown = (e) => {
		if (e.key === 'Escape' || e.key === 'Enter') {
			renew();
		}
	};
</script>

<FullPageModal on:close={handleKeydown}>
	{#if ajaxRequest}
		<SpinnerIcon css="h-9 w-9" />
	{:else}
		<h1 class="font-semibold text-lg text-center">{$_('You are about to be logged out')}</h1>
		<div class="flex items-center justify-center my-6">
			<svg viewBox="0 0 100 100" class="h-32 w-32" fill="transparent">
				<path
					d="M50,5A45 45 0 1 1 49.9999 5"
					class={value >= (SESSION_EXPIRE_SHOW_MODAL_MS - SESSION_MODAL_WARNING_MS) / 1000
						? 'text-red-500'
						: 'text-charcoal dark:text-[#d4d4d4]'}
					stroke-width="10px"
					stroke="currentColor"
				/>
				<path
					d={progressPath()}
					class="text-gray-300 dark:text-[#808080]"
					stroke-width="10px"
					stroke="currentColor"
				/>
			</svg>
			<span
				class="absolute text-5xl font-bold"
				class:text-red-500={value >=
					(SESSION_EXPIRE_SHOW_MODAL_MS - SESSION_MODAL_WARNING_MS) / 1000}
				>{!renewAjax ? SESSION_EXPIRE_SHOW_MODAL_MS / 1000 - value : ''}</span
			>
		</div>

		<div class="flex justify-between items-center w-full max-w-sm">
			<button
				on:click|stopPropagation={logoutUser}
				disabled={ajaxRequest}
				class="focus:underline hover:underline w-24 h-9 inline-flex items-center justify-center"
			>
				{$_('Log out')}
			</button>

			<!-- svelte-ignore a11y-autofocus -->
			<button
				autofocus
				on:click|stopPropagation={renew}
				disabled={renewAjax}
				class="btn-background dark:ring-white dark:bg-white dark:text-charcoal inline-flex items-center justify-center w-44 h-10"
			>
				{#if renewAjax}
					<SpinnerIcon css="h-5 w-5 text-white dark:text-[#d4d4d4]" />
				{:else}
					{$_('Stay Logged In')}
				{/if}
			</button>
		</div>
	{/if}
</FullPageModal>
